@import "config.scss";

.btn-blue,.btn-green,.btn-one,.btn-white,.btn-two,.btn-three,.btn-blue,.btn-white-blue,.btn-green-white,.btn-grey,.btn-red{
    background: map-get($colors,five);
    display: flex;
    padding: 10px 25px;
    border-radius: 5px;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    border: 0;
    cursor: pointer;
    -webkit-transition: background 500ms ease;
    -moz-transition: background 500ms ease;
    -ms-transition: background 500ms ease;
    -o-transition: background 500ms ease;
    transition: background 500ms ease;
}
.btn-green-white{  
    background: #FFF;
    color: map-get($colors,nine);
    border: 2px solid map-get($colors,nine);
    padding: 12px 25px;
}
.btn-green-white:hover{
    background: map-get($colors,nine);
    color: #FFF;
}
.btn-one{
    background: map-get($colors,one);
    border-radius: 5px;
    padding: 10px 20px;
    color: #FFF;
    border: 0;
    cursor: pointer;
}
.btn-two{
    background: map-get($colors,four);
    border-radius: 5px;
    color: map-get($colors,four); 
}
.btn-two span{
    color: #fff;
}
.btn-two .icon{
    background: #FFF;
}
.btn-blue{
    background: map-get($colors,one);
    padding: 12px 30px;
    color: #FFF;
    border: 0;
}
.btn-blue i{
    color: #FFF;
}
.btn-white-blue{
    border: 2px solid map-get($colors,one);
    padding: 13px 30px;
    color: map-get($colors,one);
    background: #FFF;
    -webkit-transition: background 500ms ease;
    -moz-transition: background 500ms ease;
    -ms-transition: background 500ms ease;
    -o-transition: background 500ms ease;
    transition: background 500ms ease;
}
.btn-white-blue:hover{
    background: map-get($colors,one);
    color: #FFF;
}
.btn-three{
    background: #ffff;
    border-radius: 5px;
    color: map-get($colors,one);
}
.btn-icon .icon{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
}
.btn-one .icon i{
    color: #fff;
}
.btn-two .icon{
    background: #fff !important;
}
.btn-two .icon i{  
    color: map-get($colors,two) !important; 
}
.btn-effect{
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.btn-white-orange{
    display: flex;
    margin: 0 auto;
    border-radius: 4px;
    padding: 12px 20px;
    border: 2px solid #da590b;
    color: #da590b;
    background: #FFF;
    cursor: pointer;
    align-items: center;
}
.btn-white-orange i,.btn-green-white i{
    margin: 0 10px 0 0;
}
.btn-white-orange:hover{  
    background: #da590b;
    color: #FFF;
}
.btn-radius{
    border-radius: 30px !important;
}
.btn-orange{
    display: flex;
    border-radius: 4px;
    padding: 12px 20px;
    border: 2px solid #da590b;
    color: #da590b; 
    background: #FFF;
    cursor: pointer;
    align-items: center;
}
.btn-icon-two{
    display: flex;
}
.btn-icon-two i{
    margin: 0 0 0 10px;
}
.btn-orange i{
    margin: 0 10px 0 0;
}
.btn-orange:hover{
    background: #da590b;
    color: #FFF;
}
.btn-grey{
    background: #f9f9f9;
    color: map-get($colors,two);
    padding: 10px 20px;
}
.btn-red{
    background: map-get($colors,two);
}
.btn-red .icon{
    background: #ff9595;
}
.btn-blue{
    background: map-get($colors,one);
    color: #FFF !important;
    border: 1px solid map-get($colors,one); 
}
.btn-blue:hover{
    color: map-get($colors,one) !important; 
    border: 1px solid map-get($colors,one) !important;  
    background: #FFF;
}
.btn-blue:hover span{
    color: map-get($colors,one) !important;   
}
.btn-blue:hover i{
    color: map-get($colors,one) !important;
}
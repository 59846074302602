
@font-face { 
    font-family: 'Kefa';
    src: url('/assets/fonts/Kefa-Regular.woff2') format('woff2'),
        url('/assets/fonts/Kefa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$fonts:(
    one: "Kefa",
    two: "Montserrat"  
); 

$colors: (
    one: #004987,
    two: #3e6994,
    three: #7486aa,
    four: #a2aec8,
    five: #d1d7e5,
    six: #e7eaf3,
    seven: #e7e8ea,
    eight: #f2f3f5,
    nine: #018268,
    ten: #dcfff8,
    eleven: #3c4043,
    twelve: #005342,
    thirteen: #008841,
    fourteen: #129b7f, 
    fifteen: #ff9349 
);

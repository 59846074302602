@charset "UTF-8";
@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
mat-form-field {
  width: 100%;
  display: table;
}

.mat-select-arrow {
  color: #004987;
}

.mat-form-field-infix {
  border-top: 0.2em solid transparent;
}

.mat-form-field-ripple,
.mat-primary .mat-option.mat-selected,
.mat-select:focus .mat-select-underline {
  background: #3e6994 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #3e6994 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3e6994 !important;
}

.mat-primary .mat-option.mat-selected {
  color: #FFF !important;
}

.mat-option-text {
  font-size: 12px;
}

.mat-form-field-infix {
  padding: 5px 0 1px !important;
}

.mat-select-trigger {
  /*padding: 16px 0 !important;*/
}

.mat-focused label,
.mat-select:focus .mat-select-placeholder {
  color: #334960 !important;
}

.mat-form-field {
  width: 100% !important;
}

.mat-input-wrapper .mat-input-info {
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 35px;
  color: #757575;
}

.mat-select-trigger {
  height: 28px !important;
}

.mat-select-placeholder,
.mat-select-value-text {
  font-size: 14px;
}

/** Material Custom **/
.md-select-custom label {
  margin: 0;
}

.md-select-custom .mat-select {
  padding-top: 0;
}

.md-select-custom .mat-select-trigger {
  height: 30px !important;
}

.md-select-custom.not-placeholder .mat-floating-placeholder {
  font-size: 0;
}

.md-select-custom .mat-select-trigger {
  min-width: 100%;
}

.mat-select {
  border-bottom: 1px solid #000;
}

.no-padding .mat-dialog-container {
  padding: 0 !important;
}

.mat-tab-label {
  font-size: 12px !important;
}

@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.btn-blue, .btn-green, .btn-one, .btn-white, .btn-two, .btn-three, .btn-blue, .btn-white-blue, .btn-green-white, .btn-grey, .btn-red {
  background: #d1d7e5;
  display: flex;
  padding: 10px 25px;
  border-radius: 5px;
  align-items: center;
  text-decoration: none;
  color: #FFF;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  border: 0;
  cursor: pointer;
  -webkit-transition: background 500ms ease;
  -moz-transition: background 500ms ease;
  -ms-transition: background 500ms ease;
  -o-transition: background 500ms ease;
  transition: background 500ms ease;
}

.btn-green-white {
  background: #FFF;
  color: #018268;
  border: 2px solid #018268;
  padding: 12px 25px;
}

.btn-green-white:hover {
  background: #018268;
  color: #FFF;
}

.btn-one {
  background: #004987;
  border-radius: 5px;
  padding: 10px 20px;
  color: #FFF;
  border: 0;
  cursor: pointer;
}

.btn-two {
  background: #a2aec8;
  border-radius: 5px;
  color: #a2aec8;
}

.btn-two span {
  color: #fff;
}

.btn-two .icon {
  background: #FFF;
}

.btn-blue {
  background: #004987;
  padding: 12px 30px;
  color: #FFF;
  border: 0;
}

.btn-blue i {
  color: #FFF;
}

.btn-white-blue {
  border: 2px solid #004987;
  padding: 13px 30px;
  color: #004987;
  background: #FFF;
  -webkit-transition: background 500ms ease;
  -moz-transition: background 500ms ease;
  -ms-transition: background 500ms ease;
  -o-transition: background 500ms ease;
  transition: background 500ms ease;
}

.btn-white-blue:hover {
  background: #004987;
  color: #FFF;
}

.btn-three {
  background: #ffff;
  border-radius: 5px;
  color: #004987;
}

.btn-icon .icon {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
}

.btn-one .icon i {
  color: #fff;
}

.btn-two .icon {
  background: #fff !important;
}

.btn-two .icon i {
  color: #3e6994 !important;
}

.btn-effect {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.btn-white-orange {
  display: flex;
  margin: 0 auto;
  border-radius: 4px;
  padding: 12px 20px;
  border: 2px solid #da590b;
  color: #da590b;
  background: #FFF;
  cursor: pointer;
  align-items: center;
}

.btn-white-orange i, .btn-green-white i {
  margin: 0 10px 0 0;
}

.btn-white-orange:hover {
  background: #da590b;
  color: #FFF;
}

.btn-radius {
  border-radius: 30px !important;
}

.btn-orange {
  display: flex;
  border-radius: 4px;
  padding: 12px 20px;
  border: 2px solid #da590b;
  color: #da590b;
  background: #FFF;
  cursor: pointer;
  align-items: center;
}

.btn-icon-two {
  display: flex;
}

.btn-icon-two i {
  margin: 0 0 0 10px;
}

.btn-orange i {
  margin: 0 10px 0 0;
}

.btn-orange:hover {
  background: #da590b;
  color: #FFF;
}

.btn-grey {
  background: #f9f9f9;
  color: #3e6994;
  padding: 10px 20px;
}

.btn-red {
  background: #3e6994;
}

.btn-red .icon {
  background: #ff9595;
}

.btn-blue {
  background: #004987;
  color: #FFF !important;
  border: 1px solid #004987;
}

.btn-blue:hover {
  color: #004987 !important;
  border: 1px solid #004987 !important;
  background: #FFF;
}

.btn-blue:hover span {
  color: #004987 !important;
}

.btn-blue:hover i {
  color: #004987 !important;
}

@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.ui-datepicker {
  border-radius: 0 !important;
  background: #3e6994;
  color: #FFF;
  font-family: "Montserrat";
}

.ui-datepicker .date-td-valor a {
  position: relative;
  cursor: pointer;
}

.ui-datepicker th {
  color: #3e6994 !important;
}

.ui-datepicker .date-td-valor {
  position: relative;
}

.ui-datepicker .date-td-valor .valor {
  font-size: 11px;
  position: absolute;
  padding: 0;
  bottom: 2px;
  text-align: center;
  width: 100%;
  color: #000;
  cursor: pointer;
}

.ui-datepicker-trigger {
  width: 30px;
  position: absolute;
  right: 0;
  top: 25px;
  cursor: pointer;
}

.ui-datepicker .date-td-valor.ui-datepicker-current-day .valor {
  color: #FFF !important;
}

.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year {
  border-radius: 5px;
  padding: 4px;
  margin: 0 5px 0 0 !important;
  font-size: 12px !important;
}

.ui-datepicker .ui-datepicker-header {
  border-radius: 0;
  background: #FFF;
  padding: 10px !important;
}

.ui-datepicker .ui-datepicker-today a {
  background: #3e6994;
  border-color: #3e6994;
  color: #FFF;
}

.ui-datepicker .ui-state-active {
  background: #3e6994;
  color: #FFF;
  border-color: #3e6994;
}

/** DATEPICKER **/
.dm-cart-data-servico .ui-datepicker {
  background: #FFF !important;
  width: 100%;
}

.dm-cart-data-servico .ui-datepicker a {
  text-align: center;
}

.dm-cart-data-servico .ui-datepicker .ui-datepicker-today a {
  background: #004987 !important;
  color: #FFF !important;
  border-color: #004987 !important;
}

.dm-cart-data-servico .ui-datepicker .ui-datepicker-header {
  border-radius: 0;
  background: #004987 !important;
  color: #FFF;
}

.dm-cart-data-servico .ui-datepicker .ui-datepicker-title {
  font-family: "Kefa" !important;
  text-transform: uppercase;
  font-weight: normal !important;
}

.dm-cart-data-servico .ui-datepicker .ui-state-active {
  background: #004987 !important;
  color: #FFF !important;
  border-color: #004987 !important;
}

.dm-cart-data-servico .ui-datepicker .ui-datepicker-next,
.dm-cart-data-servico .ui-datepicker .ui-datepicker-prev {
  background: #FFF;
}

.dm-cart-data-servico .date-td span,
.dm-cart-data-servico .date-td a {
  padding: 10px 12px;
  text-align: center;
  font-size: 16px;
}

.ui-datepicker-prev, .ui-datepicker-next {
  display: inline-block !important;
  width: 30px !important;
  height: 25px !important;
  text-align: center !important;
  cursor: pointer !important;
  background-repeat: no-repeat !important;
  line-height: 600% !important;
  overflow: hidden !important;
  background-size: 50% !important;
  height: 85% !important;
}

/*.ui-datepicker-prev {
    float: left !important;
    background-position: 8px !important;
    background-image: url('/images/icons/seta-prev.png') !important;
}
.ui-datepicker-next {
    float: right !important;
    background-image: url('/images/icons/seta.png') !important;
    background-position: 8px !important;
}*/
.input-date .ui-datepicker-trigger {
  top: 27px !important;
}

@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/** DM CHECKBOX **/
.dm-check-box {
  display: table;
  margin: 0 0 5px 0;
}

.dm-check-box-input {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: table;
  border: 2px solid #eee;
  background: #FFF;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.dm-check-box-input.active {
  background: #3e6994;
}

.dm-check-box-input,
.dm-check-box-value {
  display: table;
  float: left;
}

.dm-check-box-value {
  text-transform: uppercase;
  position: relative;
  top: -2px;
}

.dm-check-box-info {
  font-size: 10px;
  color: #004987;
  top: -2px;
}

/** MODAL DIALOG **/
.modal-dialog .message-text {
  padding: 10px;
}

.produto-form-search-two .mat-select-value-text {
  font-size: 12px;
  position: relative;
  top: 4px;
}

/** FORM CUSTOM **/
.form-custom label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #1f1f1f;
  font-size: 13px;
  font-weight: 500;
}

.form-custom label.bold {
  font-weight: 700;
}

.form-custom label i {
  padding-right: 12px;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.form-custom input[type=text],
.form-custom input[type=password],
.form-custom textarea,
.form-custom mat-select {
  float: left;
  border: none;
  background: transparent;
  width: 100%;
  padding: 10px 0;
  border-radius: 0;
  color: #1f1f1f;
  font-size: 13px;
  -webkit-appearance: none;
  border-bottom: 1px solid #9D9D9C;
  outline: none;
}

.form-custom input::-ms-input-placeholder {
  font-size: 13px;
}

.form-custom input::-webkit-input-placeholder {
  font-size: 13px;
}

.form-custom input::placeholder {
  font-size: 13px;
}

.form-custom textarea::-ms-input-placeholder {
  font-size: 13px;
}

.form-custom textarea::-webkit-input-placeholder {
  font-size: 13px;
}

.form-custom textarea::placeholder {
  font-size: 13px;
}

.form-custom textarea {
  resize: none;
  -webkit-appearance: none;
}

.form-custom mat-select {
  padding: 8px !important;
}

.form-custom mat-select .mat-select-value-text {
  position: relative;
  top: 5px;
  font-size: 12px;
}

.form-group {
  display: table;
  width: 100%;
  margin: 0 0 15px 0;
}

/** select 2 **/
.select2-container .select2-selection--single {
  height: 45px;
  outline: none !important;
  padding: 7px;
  background: #f6f6f6 !important;
  border-color: #eee;
  border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 80%;
  margin-left: -10px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
  font-size: 13px;
}

.select2, .select2-hidden-accessible {
  /**width: 100% !important;**/
  max-width: 100% !important;
  table-layout: fixed;
  display: table;
}

.select2 {
  width: 100% !important;
}

.select2-results__option[aria-selected] {
  padding: 15px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #004987 !important;
}

.input-box {
  margin: 0 0 15px 0;
  display: table;
  width: 100%;
}

.input-float input, .input-float textarea {
  border: 1px solid #7486aa !important;
  padding: 15px 20px !important;
  border-radius: 10px !important;
}

.input-float label {
  background: #FFF;
  position: absolute;
  display: table;
  width: auto;
  padding: 0 10px;
  color: #7486aa;
  margin-left: 10px;
  margin-top: -5px;
}

.input-float {
  margin-bottom: 10px;
  display: table;
  width: 100%;
}

/*--
	reset
--*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  border: none;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

a {
  text-decoration: none;
  position: relative;
  color: #000;
}

audio, video, canvas {
  max-width: 100%;
}

@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.modulo-sobre .descricao strong {
  font-family: "Kefa";
}

/** BANNER SLIDER SLICK **/
.banner-slider-slick .slick-dots,
.produto-slide-show .slick-dots,
.modulo-banner-destaque .slick-dots {
  bottom: 15px;
}

.modulo-banner-destaque .slick-dots {
  bottom: 20px !important;
}

.banner-slider-slick .slick-dots li button,
.produto-slide-show .slick-dots li button,
.modulo-banner-destaque .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.81);
  border-radius: 100%;
}

.banner-slider-slick .slick-dots li,
.produto-slide-show .slick-dots li {
  width: 5px;
}

.banner-slider-slick .slick-dots li button:before,
.produto-slide-show .slick-dots li button:before {
  content: "" !important;
}

.banner-slider-slick .slick-dots .slick-active button,
.produto-slide-show .slick-dots .slick-active button {
  background: #3e6994;
}

.produto-slide-show .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.produto-slide-show .produto-slide-show-nav {
  position: relative;
  margin: -7px 0 0 0;
}

.produto-slide-show-nav .item {
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.produto-slide-show-nav .slick-current .item {
  opacity: 1;
}

@media (max-width: 959px) {
  .modulo-dicas .modulo-titulo h2 br,
.modulo-dicas .modulo-titulo h3 br {
    display: none !important;
  }
}
@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.page {
  padding: 10px 0 100px 0;
  background: #ffffff;
}

.page .page-title {
  position: relative;
  display: table;
  width: 100%;
  text-align: left;
}

.page .page-title h2 {
  font-family: "Montserrat";
  font-size: 18px;
}

.page .descricao article {
  text-align: justify;
  font-size: 14px;
}

.page .page-title .linha-left {
  left: 0;
}

.page .page-title .linha-right {
  right: 0;
}

/** SOBRE **/
.page-sobre .descricao article h3 {
  color: #222222;
  font-size: 22px;
  margin: 40px 0;
}

/** SERVIÇO **/
.section-descricao-content .descricao p {
  margin: 0 0 10px 0;
}

.section.descricao article strong {
  font-weight: bold;
}

.produto-relacionado-slider .slick-dots li {
  margin: 0 3px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.produto-relacionado-slider .slick-dots li button:before {
  font-size: 12px;
}

.produto-relacionado-slider .slick-dots {
  margin: 0 0 20px 0;
}

.produto-relacionado-slider .slick-dots li button {
  background: #BDCBDB;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.81);
  border-radius: 100%;
}

.page-custom-title {
  color: #004987;
  font-size: 36px;
  line-height: 36px;
}

@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/** Galeria Template **/
.parada-galeria-template {
  display: none;
}

.parada-galeria-template .parada-galeria {
  position: fixed;
  z-index: 1000;
}

.parada-galeria-template .parada-galeria ul {
  padding: 0;
  z-index: 2;
  position: relative;
}

.parada-galeria-template .parada-galeria li {
  display: none;
}

.parada-galeria-template .parada-galeria li.show {
  display: block;
}

.parada-galeria-template .parada-galeria .content {
  position: fixed;
  top: 10%;
  z-index: 2000;
  display: table;
  margin: 0 auto;
  left: 20%;
}

.parada-galeria-template .parada-galeria .content .content-images {
  position: relative;
  padding: 20px;
}

.parada-galeria-template .parada-galeria .content .bg {
  background: #1c1c1b;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.9;
  filter: alpha(opacity=80);
  z-index: 1;
}

.parada-galeria-template .parada-galeria .overlay {
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.parada-galeria-template .parada-galeria li .legenda {
  display: table;
  width: 100%;
  margin: 15px 0 0 0;
}

.parada-galeria-template .parada-galeria li h5 {
  font-size: 20px;
  color: #FFF;
  display: table;
  margin: 0;
}

.parada-galeria-template .parada-galeria .close-galeria {
  position: absolute;
  z-index: 10;
  right: 20px;
  cursor: pointer;
}

.parada-galeria-template .parada-galeria .close-galeria i,
.parada-galeria-template .prev i,
.parada-galeria-template .next i {
  color: #FFF;
  font-size: 50px;
  text-shadow: 1px 2px 1px #000;
}

.parada-galeria-template .prev,
.parada-galeria-template .next {
  position: absolute;
  z-index: 10;
  top: 35%;
  cursor: pointer;
}

.parada-galeria-template .prev i,
.parada-galeria-template .next i {
  font-size: 120px;
}

.parada-galeria-template .prev {
  left: 0px;
}

.parada-galeria-template .next {
  right: 0px;
}

/** GALERIA **/
.lg-actions .lg-next, .lg-actions .lg-prev {
  color: #fff;
  background: #004987;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-top: -25px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  z-index: 1080;
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.2);
}

.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
  font-family: Font Awesome\ 5 Pro;
  content: "";
  position: relative;
  top: -8px;
}

.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
  content: "" !important;
}

.lg-actions .lg-prev i {
  position: relative;
  right: 2px;
  top: -2px;
}

.lg-actions .lg-next i {
  position: relative;
  top: -2px;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin: 0;
}

.slick-initialized .slick-slide, .slick-slide img {
  display: block;
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
  .parada-galeria-template .parada-galeria .content {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  .parada-galeria-template .parada-galeria .content {
    width: 90%;
    left: 5%;
  }
}
@media (max-width: 767px) {
  .parada-galeria-template .parada-galeria .content {
    width: 100%;
    left: 0;
  }
}
@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
}

.modal-dialog,
.mat-dialog-container {
  padding: 25px;
}

.mat-dialog-container {
  border-radius: 5px !important;
}

.mat-dialog-title {
  margin: 0;
}

.mat-modal-content {
  background: #FFF;
}

.modal .modal-header {
  padding: 5px 10px 10px 2px;
  border-bottom: 1px solid #eee;
}

.modal .modal-header h5,
.mat-modal-header h5 {
  text-align: left;
  font-size: 16px;
  color: #004987;
  font-weight: 100;
  margin: 0;
  font-weight: 600;
}

.modal .modal-footer {
  padding: 10px 0 0 0;
  margin: 0;
}

.modal-content {
  border-radius: 10px;
  padding: 10px;
}

.modal .modal-body,
.mat-modal-content .mat-dialog-content {
  font-size: 14px;
}

.mat-modal-content .mat-dialog-content {
  padding: 10px;
}

.mat-dialog-actions {
  display: table;
  float: right;
}

.mat-modal-content .mat-dialog-actions:last-child {
  margin-bottom: 0 !important;
}

.mat-modal-content .mat-dialog-actions {
  padding: 0 !important;
}

.mat-modal-content .mat-dialog-actions button {
  padding: 10px 30px !important;
}

.modal .modal-dialog {
  z-index: 2;
}

.modal .overlay {
  background: #FFF;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}

.modal-body {
  padding: 15px 2px;
}

.modal-backdrop {
  background: #FFF !important;
  display: none;
}

.modal-opened {
  z-index: -10;
  position: relative;
}

/** DM **/
.dm-modal .dm-modal-title {
  margin: 0 0 10px 0;
}

.dm-modal .dm-modal-title span {
  color: #3e6994 !important;
  font-size: 16px !important;
  font-family: !important;
}

.dm-modal .dm-modal-title .close {
  cursor: pointer !important;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  align-items: center;
  display: flex;
  background-color: #FFF;
  opacity: 1 !important;
  border: 1px solid #eee;
}

.dm-modal .dm-modal-title .close img {
  display: table;
  margin: 0 auto;
  width: 15px !important;
}

.dm-modal .dm-modal-footer {
  border-top: 1px solid #eee;
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
}

.dm-modal .dm-modal-footer button {
  margin: 0 10px 0 0;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto;
  padding: 30px 0;
}

.cdk-overlay-pane {
  position: relative !important;
}

.dm-modal .dm-modal-content {
  display: table;
  width: 100% !important;
}

.mat-modal-info h3 {
  margin: 10px 0 10px 5px;
  font-weight: bold !important;
}

.mat-modal-info h4 {
  margin: 0 0 0 7px;
  font-weight: bold;
}

.mat-dialog-container {
  border-radius: 0 !important;
}

.modal-message h4 {
  color: #000;
  font-weight: 600;
  margin: 7px 0;
}

.modal-message h3 {
  font-weight: 700;
  color: #000;
}

@media (max-width: 480px) {
  .cdk-overlay-pane {
    max-width: 95% !important;
  }
}
@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
footer h3.modulo-title {
  color: #004987;
  font-size: 14px;
  margin: 0 0 10px 0;
  font-weight: 900;
}

@media (max-width: 767px) {
  footer .modulo {
    margin: 0 0 20px 0 !important;
  }
}
@font-face {
  font-family: "Kefa";
  src: url("/assets/fonts/Kefa-Regular.woff2") format("woff2"), url("/assets/fonts/Kefa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.center-flex {
  display: flex;
  align-items: center;
}

.module h2 {
  color: #353535;
  font-size: 26px;
  font-weight: 700;
}

.module h3 {
  color: #353535;
  font-size: 18px;
  font-weight: 200;
  margin: 8px 0 0 0;
}

.module-content {
  position: relative;
}

.slide-navigate-item {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: 40%;
  cursor: pointer;
}

.slide-navigate-item i {
  color: #FFF;
  display: table;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  font-size: 16px;
}

.slide-navigate-item .bg {
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
  opacity: 0.5;
}

.slide-navigate-item.prev {
  left: -20px;
}

.slide-navigate-item.next {
  right: -20px;
}

.slide-navigate-item img {
  display: table;
  margin: 0 auto;
}

.slide-navigate-item.template-2.next {
  right: 0;
}

.slide-navigate-item.template-2.prev {
  left: 0;
}

.slide-navigate-item.template-2 {
  width: 45px;
  height: 45px;
}

.slide-navigate-item.template-2 img {
  width: 7px;
}

.center {
  display: table;
  margin: 0 auto;
}

.z-index-1 {
  position: relative;
  z-index: 1;
  display: block;
}

.z-index-2 {
  position: relative;
  z-index: 2;
  display: block;
}

.page {
  padding: 50px 0 100px 0;
}

.page-title {
  margin: 0 0 20px 0;
}

.page h1 {
  font-size: 30px;
  font-family: "Kefa";
  font-weight: 500;
  color: #004987;
}

.box-sombra {
  background: #FFF;
  box-shadow: 5px 5px 20px 0 #d4d4d4;
  padding: 20px;
}

strong {
  font-weight: 700;
}

a {
  text-decoration: none !important;
  color: #353535;
}

.title-h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.text-custom {
  font-size: 13px;
  line-height: 20px;
}

.linha {
  height: 1px;
  width: 100%;
  display: table;
  margin: 15px 0;
  background: #eee;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  display: table;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-bottom {
  top: 135% !important;
  left: 50% !important;
  margin-left: -60px !important;
}

.tooltip-bottom::after {
  content: "" !important;
  position: absolute !important;
  bottom: 100% !important;
  left: 50% !important;
  margin-left: -5px !important;
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent transparent #555 transparent !important;
  top: -10px !important;
}

.card-item {
  padding: 0.8rem 1.3rem;
  position: relative;
  margin-right: 8px;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 1px 2px 3px -2px #1f0708;
  box-shadow: 1px 2px 3px -2px #1f0708;
}

.card-item .header {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

.card-item .header span {
  font-weight: bold;
  color: #e7e8ea;
  flex: 1;
  font-size: 16px;
}

.card-item .header i {
  font-size: 18px;
  color: #3e6994;
}

.linha-grey {
  background: #f9f9f9;
  height: 5px;
  width: 100%;
  display: table;
  margin: 20px 0;
}

/** menu action partila **/
.menu-action-partial .item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-action-partial .item .icon {
  margin: 0 7px 0 0;
  border: 2px solid #e7eaf3;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #004987;
}

.menu-action-partial .item:hover .icon {
  background: #004987;
  color: #FFF;
}

.menu-action-partial .item .icon i {
  font-size: 14px;
}

.menu-action-partial .item .text span {
  font-weight: normal;
  color: #000;
}

.menu-action-partial .item .text {
  line-height: 15px;
}

.margin-p p {
  margin: 0 0 20px 0;
}

@media (max-width: 959px) {
  header .menu-desktop {
    display: none !important;
  }

  header .logo {
    margin: 0 !important;
  }

  header .logo img {
    width: 200px;
  }

  header .topo-partial .li-item,
header .topo-partial .divisor-circle {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .slide-navigate-item.prev {
    left: 0;
  }

  .slide-navigate-item.next {
    right: 0;
  }

  .slide-navigate-item {
    width: 45px;
    height: 45px;
    top: 40%;
  }

  .slide-navigate-item img {
    width: 8px;
  }

  .slick-padding-mobile .slick-list {
    padding: 10px 20px 10px 10px !important;
  }
}
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-modal {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.animated-two {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-three {
  -webkit-animation-duration: 100ms;
  animation-duration: 100ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -70%, 0);
    transform: translate3d(0, -70%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.5;
  }
  to {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.5;
  }
  to {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/* Rotating entrances */
@-webkit-keyframes rotateIn {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

html {
  -webkit-text-size-adjust: 100% !important;
}

html, body {
  height: 100%;
}

body {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

h1, h2, h3 {
  font-family: "Kefa";
}

.linha-white {
  height: 5px;
  width: 100%;
  background: #FFF;
  display: table;
  margin: 20px 0;
}

/** grid **/
.wp-layout-grid {
  display: flex !important;
  overflow: auto;
  margin: -20px -10px -20px -20px;
  padding: 20px 0 0 20px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  scroll-behavior: smooth;
}

.wp-layout-item {
  margin-right: 20px;
  -webkit-flex-basis: 290px;
  -ms-flex-preferred-size: 290px;
  flex-basis: 290px;
  flex: 0 0 290px;
  margin-bottom: 5px;
  -webkit-box-flex: 0;
}

.wp-layout-grid::-webkit-scrollbar {
  background-color: transparent !important;
}

a, button {
  outline: none !important;
}

.icon-orange {
  background: #e7eaf3;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 0 auto;
}

.icon-orange i {
  font-size: 20px;
  color: #004987;
}

.header.fixed .menu-partial a, .header.template-two .menu-partial a {
  color: #004987 !important;
}

.grecaptcha-badge {
  display: none !important;
}

a, button {
  cursor: pointer !important;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1280px;
  }
}